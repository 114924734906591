import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import iconMap from '@assets/brandIcons/index';
import * as styles from './Icon.module.css';

const Icon = ({ icon, className }) => {
  const IconComponent = iconMap[icon];

  return <IconComponent className={cn(styles.root, className)} />;
};

Icon.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.oneOf(Object.keys(iconMap)).isRequired,
};

export default Icon;
