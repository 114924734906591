// Auto generated by npm run util:updateIcons at Wed Jul 05 2023 10:35:36 GMT+0200 (Central European Summer Time)

import Webhooks from './Webhooks.svg';
import Visibility from './Visibility.svg';
import Users from './Users.svg';
import Uptime from './Uptime.svg';
import Tag from './Tag.svg';
import Support from './Support.svg';
import Success from './Success.svg';
import Stop from './Stop.svg';
import Speed from './Speed.svg';
import SelfService from './SelfService.svg';
import Rules from './Rules.svg';
import Proxy from './Proxy.svg';
import NativeTooling from './NativeTooling.svg';
import Lock from './Lock.svg';
import Formats from './Formats.svg';
import Fixes from './Fixes.svg';
import Distribution from './Distribution.svg';
import Dependencies from './Dependencies.svg';
import Costs from './Costs.svg';
import Cloud from './Cloud.svg';
import Check from './Check.svg';
import CLI from './CLI.svg';
import Bindings from './Bindings.svg';
import Availability from './Availability.svg';
import API from './API.svg';

const iconMap = {
  api: API,
  availability: Availability,
  bindings: Bindings,
  check: Check,
  cli: CLI,
  cloud: Cloud,
  costs: Costs,
  dependencies: Dependencies,
  distribution: Distribution,
  fixes: Fixes,
  formats: Formats,
  lock: Lock,
  nativetooling: NativeTooling,
  proxy: Proxy,
  rules: Rules,
  selfservice: SelfService,
  speed: Speed,
  stop: Stop,
  success: Success,
  support: Support,
  tag: Tag,
  uptime: Uptime,
  users: Users,
  visibility: Visibility,
  webhooks: Webhooks,
};

export default iconMap;
